import { useEffect, useState } from "react";
import { Card, Elevation, HTMLTable } from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import {
  G4DocumentLoadedMessage,
  GetTenantResponse,
} from "@microsearch/g4api-support";
import { subscribe } from "@microsearch/g4api-browser";

import "./TenantDocumentProcessingPage.scss";
import { session, showError } from "../..";
import { TenantPageLayout } from "../TenantPageLayout/TenantPageLayout";
import { Rows, Row } from "../../components/Layout";

import { stage } from "../../version";

type G4Tenant = GetTenantResponse;

let unsubscribe: (() => void) | null = null;

export const TenantDocumentProcessingPage = () => {
  const { tid } = useParams<{ tid: string }>();
  const [tenant, setTenant] = useState<G4Tenant | undefined>();
  const [docMessages, setDocMessages] = useState<G4DocumentLoadedMessage[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = (await session.tenant.get(+(tid as string))).data;
        setTenant(response);
        cleanupSubscription();
        unsubscribe = subscribe({
          endpoint: stage,
          tenant: response.name,
          bearer: () => session.bearer!,
          onerror: (event: Event) => console.log("subscription error:", event),
          subs: {
            document: {
              loaded: (message) => {
                setDocMessages([...docMessages.slice(-24), message]);
              },
            },
          },
        });
      } catch (error) {
        if (error instanceof Error) showError(error.message);
      }
    })();
    return cleanupSubscription;
  }, [tid, docMessages]);

  return (
    <>
      {tenant && (
        <TenantPageLayout tenant={tenant} selector="document-processing">
          <Rows>
            <Row>
              <Card elevation={Elevation.TWO}>
                <h3>Document Loading Events</h3>
                <HTMLTable className="event-table" condensed bordered striped>
                  <thead>
                    <tr>
                      <td></td>
                      <td>Username</td>
                      <td>Filename</td>
                      <td>Document Signature</td>
                    </tr>
                  </thead>
                  <tbody>
                    {docMessages.map((message) => (
                      <tr>
                        <td>
                          <div className="event-table-loaded">
                            {new Date(
                              message.loaded + " UTC"
                            ).toLocaleTimeString()}
                          </div>
                        </td>
                        <td>
                          <div className="event-table-username">
                            {message.username}
                          </div>
                        </td>
                        <td>
                          <div className="event-table-filename">
                            {message.filename}
                          </div>
                        </td>
                        <td>
                          <div className="event-table-signature">
                            {message.signature}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </HTMLTable>
              </Card>
            </Row>
          </Rows>
        </TenantPageLayout>
      )}
    </>
  );
};

function cleanupSubscription() {
  if (unsubscribe) {
    unsubscribe();
    unsubscribe = null;
  }
}
