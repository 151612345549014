import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster, Position, Intent } from "@blueprintjs/core";
import { G4BrowserSession } from "@microsearch/g4api-browser";

import "./index.scss";
import { g4api } from "./version";
import { G4Route } from "./G4Route";
import { NotFoundPage } from "./pages/NotFoundPage";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { HomePage } from "./pages/HomePage/HomePage";
import { AdminsPage } from "./pages/AdminsPage/AdminsPage";
import { TenantDashboardPage } from "./pages/TenantDashboardPage/TenantDashboardPage";
import { TenantUsersPage } from "./pages/TenantUsersPage/TenantUsersPage";
import { TenantUserEventsPage } from "./pages/TenantUserEventsPage/TenantUserEventsPage";
import { TenantRolesPage } from "./pages/TenantRolesPage/TenantRolesPage";
import { TenantProfilesPage } from "./pages/TenantProfilesPage/TenantProfilesPage";
import { AdminEventsPage } from "./pages/AdminEventsPage/AdminEventsPage";
import { AdminRolesPage } from "./pages/AdminRolesPage/AdminRolesPage";
import { TenantDocumentProcessingPage } from "./pages/TenantDocumentProcessingPage/TenantDocumentProcessingPage";

export const session = new G4BrowserSession({
  endpoint: g4api,
  application: "g4admin",
});

const toaster = Toaster.create({
  position: Position.TOP_RIGHT,
});

const TOASTER_TIMEOUT = 5; // seconds

export function showError(message: string) {
  toaster.show({
    intent: Intent.DANGER,
    message: message,
    icon: "error",
    timeout: TOASTER_TIMEOUT * 1000,
  });
}

export function showWarning(message: string) {
  toaster.show({
    intent: Intent.WARNING,
    message: message,
    icon: "warning-sign",
    timeout: TOASTER_TIMEOUT * 1000,
  });
}

export function showSuccess(message: string) {
  toaster.show({
    intent: Intent.SUCCESS,
    message: message,
    icon: "info-sign",
    timeout: TOASTER_TIMEOUT * 1000,
  });
}

export function showInfo(message: string) {
  toaster.show({
    intent: Intent.PRIMARY,
    message: message,
    icon: "info-sign",
    timeout: TOASTER_TIMEOUT * 1000,
  });
}

const G4AdminApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<LoginPage />} />

        <Route path="/" element={<G4Route outlet={<HomePage />} />} />
        <Route path="/admins" element={<G4Route outlet={<AdminsPage />} />} />
        <Route
          path="/admin-events"
          element={<G4Route outlet={<AdminEventsPage />} />}
        />
        <Route
          path="/admin-roles"
          element={<G4Route outlet={<AdminRolesPage />} />}
        />
        <Route
          path="/tenant/dashboard/:tid"
          element={<G4Route outlet={<TenantDashboardPage />} />}
        />
        <Route
          path="/tenant/users/:tid"
          element={<G4Route outlet={<TenantUsersPage />} />}
        />
        <Route
          path="/tenant/user-events/:tid/:uid"
          element={<G4Route outlet={<TenantUserEventsPage />} />}
        />
        <Route
          path="/tenant/user-events/:tid"
          element={<G4Route outlet={<TenantUserEventsPage />} />}
        />
        <Route
          path="/tenant/document-processing/:tid"
          element={<G4Route outlet={<TenantDocumentProcessingPage />} />}
        />
        <Route
          path="/tenant/roles/:tid"
          element={<G4Route outlet={<TenantRolesPage />} />}
        />
        <Route
          path="/tenant/profiles/:tid"
          element={<G4Route outlet={<TenantProfilesPage />} />}
        />
        <Route element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <G4AdminApp />
  </React.StrictMode>,
  document.getElementById("root")
);
